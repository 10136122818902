

export const banner=[
    {
        source: ("./images/CarouselImages/carousel1.jpg"),
        title: "Handmade With Love",
    },
    {
        source: ("./images/CarouselImages/carousel2.jpg"),
        title: "Traditional Dresses",
    },
    {
        source: ("./images/CarouselImages/carousel3.jpg"),
        title: "Handloom Dresses",
    },
];